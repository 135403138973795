import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";

import {
  Hero,
  Offer,
  News,
  Guide,
  Why,
  Opinions,
  Gallery,
  Content,
} from "page_components/main";
import InfoBanner from "components/InfoBanner";

import { getWpPage } from "utils";

const Home = ({ data }) => {
  const pageData = getWpPage(data, "Main");
  const seo = data?.allWpPage?.nodes?.[0]?.seo;

  return (
    <Layout rework>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Hero {...pageData?.hero} />
      <Offer {...pageData?.offer} />
      <News {...pageData?.news} />
      <Guide {...pageData?.guide} />
      <InfoBanner
        image={pageData?.banner.image?.gatsbyImage}
        title={pageData?.banner.content.title}
        url={pageData?.banner.content.link}
      />
      <Why {...pageData?.why} />
      <Opinions />
      <Gallery />
      <Content {...pageData?.info} />
    </Layout>
  );
};

export const query = graphql`
  {
    allWpPage(filter: { id: { eq: "cG9zdDo4MzI3" } }) {
      nodes {
        title
        seo {
          metaDesc
          metaKeywords
          title
        }
        acfMain {
          mainHeroClinic
          mainHeroTitle
          mainHeroCaption
          mainOfferList {
            image {
              gatsbyImage(
                width: 872
                quality: 90
                formats: WEBP
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
            content {
              title
              description
              link
            }
          }
          mainNewsList {
            image {
              gatsbyImage(
                width: 872
                quality: 90
                formats: WEBP
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
            content {
              title
              description
              link
            }
          }
          mainGuideList {
            post {
              ... on WpPost {
                id
                title
                slug
                acfPost {
                  postUpdateDate
                }
                excerpt
                featuredImage {
                  node {
                    gatsbyImage(
                      width: 1280
                      quality: 90
                      formats: WEBP
                      placeholder: BLURRED
                      layout: FULL_WIDTH
                    )
                  }
                }
                author {
                  node {
                    avatar {
                      url
                    }
                    name
                  }
                }
                categories {
                  nodes {
                    name
                    slug
                  }
                }
              }
            }
          }
          mainBannerImage {
            gatsbyImage(
              width: 900
              quality: 90
              formats: WEBP
              placeholder: BLURRED
              layout: FULL_WIDTH
            )
          }
          mainBannerContent {
            title
            link
          }
          mainWhyContents {
            title
            description
          }
          mainInfoSlides {
            contents {
              description
            }
          }
        }
      }
    }
  }
`;

export default Home;
